








































import { Vue, Component } from "vue-property-decorator";
import moment from "moment";

import DateDialog from "@/components/dialogs/DateDialog.vue";
import DocumentsDialog from "@/components/dialogs/DocumentsDialog.vue";

@Component({
    components: {
        "date-dialog": DateDialog,
        "documents-dialog": DocumentsDialog,
    },
})
export default class ProlongUsers extends Vue {
    private isDateDialogVisible: boolean = false;
    private isDocDialogVisible: boolean = false;

    private data(): object {
        const date = moment().add(4, "days").format("YYYY-MM-DD");

        return {
            dateRange: [date, date],
            users: [],
        };
    }

    private closeDateDialog() {
        this.isDateDialogVisible = false;
    }

    private openDateDialog() {
        this.isDateDialogVisible = true;
    }

    private async setDateRange(dateRange: any[]) {
        this.$data.dateRange = dateRange;

        this.closeDateDialog();

        await this.getUsersForProlong(this.$data.dateRange);
    }

    private async getUsersForProlong(dateRange: any[]) {
        const [startDate, endDate] = dateRange;

        try {
            const res = await this.$Api.Main.GetUsersForProlong(startDate, endDate);

            this.$data.users = res.users.map((s) => ({ ...s, date: moment(s.date).format("YYYY-MM-DD"), carName: `${s.brandName} ${s.modelName}` }));
        } catch (error) {
            alert(error.message || error);
        }

        this.closeDateDialog();
    }


    private async openUrlDialog(row: any) {
        const res = await this.$Api.Main.GetUserProlongUrl(row.id);

        if (res.status === "OK") {
            prompt("Ссылка пролонгации", res.prolongUrl);
        } else {
            alert(res.message);
        }

    }
}
